export default {
    logo: 'YourDailyWorkouts',
    nav:{
        home: 'Home',
        unsubscribe: 'Il mio abbonamento',
    },
    mailSent:'La vostra mail è stata inviata.',
    getInTouch:{
        title: 'RICEVI IN TOCCO',
        firstName: 'Nome di battesimo',
        lastName: 'Cognome',
        email: 'E-mail',
        msg: 'Messaggio',
        send: 'Inviare',
        cancelYourSubscription: 'ANNULLA LA TUA ISCRIZIONE',
        inAOneClick: 'IN UNO CLICCA CON',
        ourOnlineCancel: 'IL NOSTRO SERVIZIO DI CANCELLAZIONE ONLINE',
        text1: 'I nostri operatori conoscono tutte le risposte e ti daranno consigli efficaci e utili nel tuo caso.',
        text2: 'I nostri servizi di abbonamento ti offrono la soluzione di tutte le differenze dei tuoi problemi con i nostri programmi di coaching unici, assistenza al cliente e competenza qualificata. Perseguiamo un obiettivo: rendere la vita luminosa e leggera.',
        desc1: 'Esperti veloci ed efficienti',
        desc2: 'Partner professionali',
        desc3: 'Specialista dei servizi di affinità',
        desc4: '100% gratuito, nessun dato bancario',
    },
    questions:{
        title1: 'SERVIZIO CLIENTI PER TUTTE LE SOTTOSCRIZIONI ONLINE',
        desc1: 'Facile gestione dei tuoi abbonamenti. Ti offriamo una gestione pratica dei diversi abbonamenti. Compila il modulo con l\'indirizzo e-mail che hai utilizzato quando ti sei iscritto e sei nel tuo account cliente in un clic. Tutte le informazioni sono lì per rendere confortevole la tua gestione e darti il massimo controllo dei tuoi abbonamenti. Puoi anche contattarci per chiedere aiuto, annullare l\'iscrizione o presentare un reclamo. Tutto è pronto per garantire che tu possa gestire tutti gli abbonamenti in un modo più semplice possibile.',
        title2: 'AGENTI AL TUO SERVIZIO',
        desc2: 'I nostri agenti sono esperti nei servizi post-vendita e hanno tutte le soluzioni dei tuoi problemi. Funzionano 24 ore su 24, 7 giorni su 7, quindi è possibile contattare gli agenti tramite chat dal vivo o telefonicamente tramite il nostro numero verde in qualsiasi momento. Puoi anche inviare loro un\'e-mail a cui risponderanno entro 48 ore. Se hai problemi con la gestione dell\'abbonamento sui porti o hai altre domande, contattaci.',
        faq: 'domande frequenti ',
        faqList:[
            {
                id:1,
                show: false,
                question: 'Quali servizi offrite?',
                answer: 'Il nostro servizio ti offre il modo più semplice per gestire il tuo abbonamento. Devi solo accedere al tuo account cliente e compilare il modulo con un indirizzo e-mail. I nostri agenti ti contatteranno e potrai ottenere tutte le informazioni relative al tuo abbonamento o annullarlo.'
            },
            {
                id:2,
                show: false,
                question: 'Non ricordo di aver firmato il tuo abbonamento.',
                answer: 'Non ti sei iscritto al nostro abbonamento. Probabilmente hai sottoscritto un abbonamento presso uno dei nostri siti partner per il quale forniamo il servizio post-vendita. Questo è il motivo per cui hai addebiti diretti sul tuo estratto conto bancario.'
            },
            {
                id:3,
                show: false,
                question: 'Come posso trovare la mia offerta scelta?',
                answer: 'Se vuoi conoscere i dettagli della tua offerta, inserisci il tuo indirizzo email di registrazione nel modulo sulla homepage e poi il nostro agente ti contatterà e fornirà le informazioni che ti interessano sul tuo abbonamento.'
            },
            {
                id:4,
                show: false,
                question: 'Ho dimenticato il mio indirizzo email registrato? Cosa posso fare?',
                answer: 'I nostri agenti ti aiuteranno con questo problema. Troveranno il tuo file, devi solo contattarlo via email, telefono o chat.'
            },
            {
                id:5,
                show: false,
                question: 'Ho una domanda, con chi posso contattare?',
                answer: 'Il nostro team amichevole lavora 24 ore su 24, 7 giorni su 7, quindi puoi contattarci in qualsiasi momento per chattare o per telefono. Puoi anche inviarci l\'e-mail con la tua domanda e ti risponderemo entro 48 ore.'
            },
            {
                id:6,
                show: false,
                question: 'Come posso cancellare il mio abbonamento?',
                answer: 'Se desideri annullare l\'abbonamento, devi accedere al tuo account cliente e fare clic sul pulsante "Annulla iscrizione". Ma non potrai più beneficiare dei servizi del nostro sito e non saranno più addebitati dai portali. Verrà inviata un\'e-mail di conferma.'
            },
            {
                id:7,
                show: false,
                question: 'Ho qualche difficoltà nell\'utilizzare l\'area membri, cosa dovrei fare?',
                answer: 'Se le tue difficoltà sono connesse all\'uso del tuo account personale, contatta il nostro supporto tecnico. Ti aiuteranno a risolvere il problema che ti riguarda.'
            },
            {
                id:8,
                show: false,
                question: 'Non riconosco la transazione sul mio estratto conto?',
                answer: 'Se powerliftguru.com è menzionato sul conto bancario o sull\'estratto conto della carta di credito, ciò significa che ti abbiamo addebitato per conto di un commerciante online e il nostro servizio clienti è a tua disposizione per aiutarti a risolvere il tuo problema e rispondere alla tua richiesta. Avrai bisogno di almeno due delle seguenti informazioni: carta di credito, e-mail o riferimento del membro.'
            },
            {
                id:9,
                show: false,
                question: 'Voglio cancellare la mia registrazione? Come posso fare questo?',
                answer: 'Hai due modi per farlo. Puoi farlo da solo nel tuo account personale o contattare il nostro servizio. Per questa operazione, avremo bisogno di alcune informazioni: nome utente, carta di credito, e-mail o riferimento del membro.'
            },
            {
                id:10,
                show: false,
                question: 'Ho cancellato la mia registrazione ma ho ancora accesso all\'area membri',
                answer: 'Ti invieremo sempre un\'email di conferma dopo la cancellazione. Ciò garantisce che non ti addebiteremo alcun addebito per il prossimo ciclo di fatturazione. Se hai ricevuto l\'e-mail di cancellazione, è sicuro che non ti verrà addebitato nuovamente. Tuttavia, continuerai ad accedere all\'area membri fino alla fine del periodo di pagamento.'
            },
            {
                id:11,
                show: false,
                question: 'Posso inviare il numero della mia carta di credito per e-mail?',
                answer: 'Non inviare mai il numero della tua carta di credito per e-mail. Questa è un\'informazione riservata. Utilizza sempre i nostri moduli online per la tua sicurezza. Se per qualche motivo non puoi farlo, chiama il nostro servizio clienti.'
            },
            {
                id:12,
                show: false,
                question: 'Qual è il codice CVV e dove posso trovarlo?',
                answer: 'È un codice di sicurezza aggiuntivo per proteggerti da possibili frodi e viene stampato sul retro della carta. Consiste degli ultimi 3 numeri che seguono il nome del tuo account sul retro della carta di credito.'
            },
            {
                id:13,
                show: false,
                question: 'Ho dimenticato l\'indirizzo web del sito che ho comprato. Come posso recuperarlo?',
                answer: 'Per ricevere l\'indirizzo Web per e-mail: - Se si dispone di almeno due dei seguenti dettagli: nome utente, carta di credito, e-mail o riferimento del membro.'
            },
            {
                id:14,
                show: false,
                question: 'Ho una domanda a cui le FAQ non rispondono. Cosa posso fare?',
                answer: 'Se avete problemi o domande, saremo felici di sentirvi e fornire assistenza qualificata. Il nostro team lavora per te e si impegna a fornirti servizi di alta qualità. Non esitate a contattarci!'
            },
            {
                id:15,
                show: false,
                question: 'Ho problemi ad accedere al mio account. Username e password non funzionano. Cosa dovrei fare?',
                answer: 'Assicurati di aver inserito i tuoi dati esattamente come indicato nella conferma dell\'email. Per ricevere l\'indirizzo web via e-mail abbiamo bisogno del tuo nome utente, carta di credito, e-mail o riferimento del membro.'
            },
        ],
        title3: 'SIAMO QUI PER FARE LA TUA VITA PIÙ LEGGERA',
        subTitle3: 'stai con noi e usalo',
        desc3: 'supporto 24 ore su 24, i nostri esperti sanno cosa stai cercando',
        desc4: 'risposta rapida e soluzione della richiesta entro 12 ore',
        desc5: 'servizio assolutamente gratuito',
        desc6: 'le soluzioni che propongono i nostri esperti sono sempre chiare e semplici',
    },
    cancellation:{
        title1: 'RICHIESTA DI ANNULLAMENTO',
        titleForm1: 'Indirizzo e-mail utilizzato durante l\'acquisto',
        info1: 'Non condivideremo queste informazioni con terze parti',
        text1: 'Selezionando questa casella, autorizzo l\'annullamento dell\'iscrizione e la cancellazione del mio account dal database. Questa azione terminerà ogni fatturazione successiva',
        address1: 'Sofia',
        errorEmail: 'Inserisci un indirizzo e-mail valido',
        workTime: 'Call center Funziona dalle 10:00 alle 17:00',
    }
}